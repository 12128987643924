html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

body {
    font-family: 'Poppins', sans-serif !important;
	font-weight: normal;
	font-style: normal;
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #d4d4d4 !important;
}

main {
    display: block;
}

.slider-img1, .slider-img2 {
    position: absolute;
    bottom: 0px;
    animation-delay: 0.6s;
}

.slider-img1 {
    right: 135px;
}

.slider-img2 {
    right: 295px;
    z-index: -1;
}

.slider-circle {
    position: absolute;
    right: 410px;
    bottom: 0;
    z-index: -2;
    opacity: .07;
}

@media (max-width: 1199px) {
    .slider-img1, .slider-img2 {
        display: none;
    }
}

.gray-bg {
    background: #f6f6f6;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pt-115 {
    padding-top: 115px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-35 {
    margin-bottom: 35px;
}

.footer-bg {
    background-color: #f9f9f9;
    background-position: top center;
    background-size: cover;
    padding-bottom: 65px;
    position: relative;
    z-index: 999;
}

.s-copyright-wrap {
    background: #161417;
}

.copyright-wrap {
    padding: 20px 0;
}

.newsletter-wrap {
    background-color: #1c181e;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 65px;
    margin-top: -55px;
    position: relative;
    z-index: 1;
    margin-bottom: 80px;
}

@media (max-width: 1199.98px) {
    .newsletter-wrap {
        flex-direction: column;
    }

    .newsletter-title {
        margin-bottom: 30px;
    }
}

.newsletter-title h2 {
    letter-spacing: 0;
    text-transform: uppercase;
}

.section-title h2 {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 0;
    line-height: 1;
}

.section-title h2 span {
    color: #225fc3;
}

.copyright-text p a {
    font-weight: 800;
    color: #225fc3;
    outline: medium none;
    text-decoration: none;
}

.footer-text p {
    font-size: 15px;
    color: #90949c;
    margin-bottom: 15px;
}

.copyright-text p {
    font-size: 14px;
    color: #90949c;
    margin-bottom: 0;
}

.newsletter-form form {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.newsletter-form form input {
    background: #262228;
    width: 495px;
    border: none;
    color: #fff;
    padding: 17px 40px;
    font-size: 14px;
    font-weight: 500;
}

.newsletter-form form input::placeholder {
	color: #a0a0a0;
}

input:focus {
    text-transform: uppercase;
    outline: none;
}

@media (max-width: 991.98px) {
    .newsletter-form form input {
        width: 320px;
    }
}

.newsletter-form-grp {
    position: relative;
}

.newsletter-form-grp::before {
    border-color: transparent transparent #262228 transparent;
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 55px 20px;
}

.newsletter-form-grp::after {
    border-color: #262228 transparent transparent transparent;
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    height: 0;
    border-style: solid;
    border-width: 55px 20px 0 0;
}

.newsletter-form button {
    border: none;
    color: #1c1121;
    font-size: 14px;
    font-weight: 800;
    padding: 17px 15px;
    margin-left: 30px;
    position: relative;
    cursor: pointer;
    background: #225fc3;
}

.newsletter-form button::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 55px 20px;
    border-color: transparent transparent#225fc3 transparent;
}

.newsletter-form button::after {
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 55px 20px 0;
    border-color: #225fc3 transparent transparent transparent;
}

.newsletter-form button i {
    font-size: 12px;
    margin-left: 5px;
}

.newsletter-form-grp i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    left: 15px;
    color:#225fc3;
}

.footer-contact ul li {
    margin-bottom: 10px;
    font-size: 15px;
    color: #90949c;
    list-style: none;
}

.footer-contact ul {
    margin: 0px;
    padding: 0px;
}

.footer-contact ul li i {
    margin-right: 10px;
}

.s-footer-bg .fw-title h5 {
    font-size: 16px;
    text-transform: uppercase;
}

.fw-title h5 {
    font-size: 18px;
    color: #c8c8c8;
    margin-bottom: 0;
    line-height: 1;
    padding-bottom: 20px;
    position: relative;
    font-family: 'Oxanium', cursive;
    font-style: normal;
    margin-top: 0px;
    font-weight: 800;
}

.fw-title h5::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 3px;
    background:#0943A1;
}

.fw-link ul {
    margin: 0px;
    padding: 0px;
}

.fw-link ul li {
    list-style: none;
    display: block;
}

.fw-link ul li a {
    color: #90949c;
    font-size: 14px;
    line-height: 40px;
    display: inline-block;
    transition: all 0.3s ease-out 0s;
    outline: medium none;
    text-decoration: none;
}

.fw-link ul li a:hover {
    padding-left: 5px;
    color:#225fc3;
}

.footer-social ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
}

.footer-social ul li {
    list-style: none;
    display: block;
    margin-right: 9px;
}

.footer-social ul li a {
    width: 36px;
    height: 36px;
    background-color: rgb(59, 89, 152);
    display: block;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    border-radius: 2px;
}

.footer-social ul li:nth-child(2) a {
    background: #55acee;
}

.footer-social ul li:nth-child(3) a {
    background: #d71e18;
}

.container-full-padding {
    padding: 0 260px !important;
    z-index: 2;
}

@media (max-width: 991.98px) {
    .container-full-padding {
        padding: 0 30px !important;
    }
}

@media (max-width: 1199.98px) {
    .container-full-padding {
        padding: 0 15px !important;
    }
}

@media (max-width: 1500px) {
    .container-full-padding {
        padding: 0 30px !important;
    }
}

@media (max-width: 1800px) {
    .container-full-padding {
        padding: 0 60px !important;
    }
}

@media (max-width: 991.98px) {
    .hero-content {
        text-align: center;
        padding-top: 125px;
    }
}

.hero-content h2 {
    font-size: 90px;
    font-weight: 800;
    line-height: .9;
    margin-bottom: 12px;
    letter-spacing: -3px;
    text-transform: capitalize;
    font-style: normal;
    font-family: 'Oxanium', cursive;
    animation-delay: .4s;
}

@media (max-width: 1199.98px) {
    .hero-content h2 {
        font-size: 4.2rem;
    }
}

@media (max-width: 991.98px) {
    .hero-content h2 {
        font-size: 4.2rem;
    }
}

.hero-content h2 span {
    color:#225fc3;
}

.hero-content p {
    font-weight: 400;
    color: #dbdbdb;
    width: 80%;
    margin: 0 0 45px;
    font-size: 16px;
    line-height: 30px;
    animation-delay: .6s;
}

@media (max-width: 991.98px) {
    .hero-content p {
        width: 80%;
        margin: auto;
    }
}

@media (max-width: 1199.98px) {
    .hero-content p {
        width: 90%;
    }
}

@media (max-width: 991.98px) {
    .slider-circle {
        right: 70px !important;
    }
}

@media (max-width: 1199.98px) {
    .slider-circle {
        right: 170px;
        bottom: 50px;
    }
    .slider-circle img {
        width: 600px;
    }
}

@media (max-width: 1500px) {
    .slider-circle {
        right: 310px;
    }
    .slider-circle img {
        width: 650px;
    }
}

.btn {
	-moz-user-select: none;
	border: medium none;
	border-radius: 5px;
	color: #1c1121;
	cursor: pointer;
	display: inline-block;
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 1;
	margin-bottom: 0;
	padding: 20px 44px;
	text-align: center;
	text-transform: uppercase;
	touch-action: manipulation;
	transition: all 0.3s ease 0s;
	vertical-align: middle;
	white-space: nowrap;
	position: relative;
	z-index: 1;
}
.btn:hover {
	color: #1c1121;
}
.btn::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -5px;
	width: 100%;
	height: 55px;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 5px;
	z-index: -1;
	transition: .3s linear;
}
.btn:hover::before {
	bottom: 0;
}
.btn i {
	margin-right: 5px;
}
.btn.btn-style-one {
	background: transparent;
	padding: 20px 35px;
    margin: 0 8px;
    font-family: 'Poppins', sans-serif;
    animation-delay: .8s;
    font-weight: 700;
}
.btn.btn-style-one::before {
	content: "";
	position: absolute;
	right: -13px;
	top: 0;
	height: 100%;
	width: 5px;
	transform: skewX(-16deg);
	z-index: 1;
	opacity: 1;
	background-image: none;
	bottom: 0;
	border-radius: 0;
	left: auto;
	background-color:#225fc3;
}
.btn.btn-style-one::after {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 100%;
	transform: skewX(-16deg);
	z-index: -1;
	opacity: 1;
	background-image: none;
	bottom: 0;
	border-radius: 0;
	left: auto;
	background-color:#225fc3;
}
.btn.btn-style-one:hover::before {
	right: 0;
}

.team-area {
    background-position: center;
    background-size: cover;
    padding: 60px 0;
    position: relative;
}

.brand-item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 82px;
}

.brand-item img {
    display: inline-block;
}

.pt-120 {
    padding-top: 120px;
}

.game-single-content {
    box-shadow:  0px 2px 21px 0px rgba(59,53,63,0.12);
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 50px 40px;
    overflow: hidden;
}

.upcoming-game-ahead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.game-single-content .upcoming-game-ahead {
    display: block;
    margin-bottom: 35px;
}

.game-single-content p {
    font-size: 14px;
    color: #6c6c6c;
    margin-bottom: 20px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-30 {
    margin-bottom: 30px;
}

.game-single-content .game-single-title h4, .game-single-content .uc-game-head-title h4 {
    font-size: 24px;
    color: #1c1121;
    text-transform: uppercase;
    margin-bottom: 0;
    font-family: 'Oxanium', cursive;
    font-style: normal;
    font-weight: 800;
}

.mb-65 {
    margin-bottom: 65px;
}

.game-single-info ul {
    margin: 0px;
    padding: 0px;
}

.game-single-info ul li {
    color: #6c6c6c;
    font-size: 14px;
    /* display: block; */
    padding-bottom: 18px;
    border-bottom: 1px solid #e6e5e4;
    margin-bottom: 8px;
    list-style: square inside;
}

.game-single-gallery img {
    width: 100%;
    margin-top: 30px;
}

.breadcrumb-content h2 {
    font-size: 70px;
    text-transform: uppercase;
    margin-bottom: 15px;
    line-height: .8;
    font-family: 'Oxanium', cursive;
    font-weight: 800;
    color: #1c1121;
}

.breadcrumb-content h2 span {
    color: #225fc3;
}

@media (max-width: 767.98px) {
    .newsletter-form-grp {
        margin: 0 10px;
    }

    .newsletter-form form {
        margin-right: 0;
        flex-direction: column;
    }

    .newsletter-title {
        margin-bottom: 30px;
    }

    .newsletter-wrap {
        flex-direction: column;
        padding: 40px 15px;
    }

    .newsletter-form button {
        margin-left: 0;
        margin-top: 15px;
    }
}

.hero-content a i {
    font-size: 12px;
    margin-left: 5px;
}

.img-responsive {
    width: 100%;
    height: auto;
}

